.editor, .app.night-mode .editor
    {
    height: calc(100% - 160px);
    margin-top: 80px;
    position: relative;
    line-height: 1.65;
    font-size: 14pt;

    textarea
        {
        display: block;
        overflow: auto;
        width: 100%;
        padding: 0;
        height: 100%;
        margin: 0px;
        border: none;
        outline: none;
        background: transparent;
        line-height: 1.65;
        caret-color: red;
        font-size: 14pt;
        resize: none;
        transition: all .1s linear;
        &::selection
            {
            color: white;
            background: red;
            }
        &::-webkit-scrollbar { display: none; }
        }

    &.danger textarea
        {
        color: $red;
        transition: all 3s linear;
        filter: blur(2px);
        }


    &.hardcore textarea:not(:empty)
        {
        filter: blur(8px);
        }

    .hardcore
        {
        font-size: 300pt;
        text-align: center;
        color: $grey;
        width: 100%;
        // max-width: 600px;
        position: absolute;
        height: calc(100% - 60px);
        margin: 0px auto;
        box-sizing: border-box;
        pointer-events: none;
        }

    &:before, &:after
        {
        content: "";
        width: 100%;
        height: 6em;
        display: block;
        position: absolute;
        left: 0px;
        top: 0;
        pointer-events: none;
        transition: all 2s linear;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 100%);
        }
    &.cut-top:before
        {
        background: linear-gradient(to bottom, $white 0%, rgba(255,255,255,0) 100%);
        }
    &.cut-bottom:after
        {
        top: auto; bottom: 0px;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, $white 100%);
        }
    }

.app.night-mode .editor
    {
    textarea { color: $off-white; }
    .hardcore { color: $off-white; }
    &.cut-top:before { background: linear-gradient(to bottom, $dark 0%, transparent 100%); }
    &.cut-bottom:after { background: linear-gradient(to bottom, transparent 0%, $dark 100%); }
    }
